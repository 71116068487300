(function($) {
	$(document).ready(function() {

		var parallax = {
			settings: {
				elem: $('.parallax'),
			},
			init: function() {
				this.bindUIActions();
			},
			bindUIActions: function() {
				var _this = this;
				$(window).on('scroll', function() {
					var wS = $(window).scrollTop();
					_this.settings.elem.each(function() {
						var elem = _this.settings.elem;
						elem.css('top', (wS * .2) + 'px');
					})
				});
			}
		}
		parallax.init();

	});
})(jQuery);
